import {
  Instance_default
} from "./chunk-YFNULCPH.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";

// src/Persona/PersonaRoute.ts
var PersonaRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    /**
     * function getPersonaRequest get specified Persona Data from Aesir Redcore WS
     * @param  personaId
     * @return JSON
     */
    this.getPersonaRequest = (personaId) => Instance_default.get(
      this.createRequestURL({
        option: "persona",
        id: personaId
      })
    );
    /**
     *
     * @param data
     */
    this.createPersonaRequest = (data) => Instance_default.post(
      this.createRequestURL({
        option: "persona"
      }),
      data
    );
    /**
     *
     * @param data
     */
    this.updatePersonaRequest = (data) => Instance_default.put(
      this.createRequestURL({
        option: "persona"
      }),
      data
    );
    /**
     *
     * @param personaId
     */
    this.deletePersonaRequest = (personaId) => {
      const ids = personaId.split(",");
      if (ids.length < 2) {
        return Instance_default.delete(
          this.createRequestURL({
            option: "persona",
            id: personaId
          })
        );
      } else {
        return Instance_default.post(
          this.createRequestURL({
            option: "persona",
            task: "deleteAll"
          }),
          {
            id: personaId
          }
        );
      }
    };
    this.getPersonasRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "persona",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
    this.getPersonaMasterDataRequest = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "persona",
          task: "getMasterData"
        })
      );
    };
    this.getConnectedChannelByOrganisationIdRequest = (organisationId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "persona",
          task: "getConnectedChannelByOrganisationId",
          organisationId
        })
      );
    };
    /**
     * function getConnectedChannelByPersonaIdsRequest
     * @param  personaIds = '123, 456'
     * @return JSON
     */
    this.getConnectedChannelByPersonaIdsRequest = (personaIds) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "persona",
          task: "getConnectedChannelByPersonaIds",
          persona_id: personaIds
        })
      );
    };
    this.searchPersonasRequest = (dataFilter, page = 1, limit = 20) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "persona",
          task: "filterPersona",
          limitStart: (page - 1) * limit,
          limit,
          ...dataFilter
        })
      );
    };
    this.getMemberInfoRequest = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "persona",
          task: "getMemberInfo"
        })
      );
    };
  }
};
var PersonaRoute_default = PersonaRoute;

export {
  PersonaRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
