import {
  Instance_default
} from "./chunk-YFNULCPH.js";
import {
  BaseRoute_default
} from "./chunk-TY443UAN.js";

// src/Campaign/CampaignRoute.ts
var CampaignRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    /**
     * function getCampaignRequest get specified Campaign Data from Aesir Redcore WS
     */
    this.getCampaignRequest = (campaignId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "campaign",
          id: campaignId
        })
      );
    };
    /**
     *
     * @param data
     */
    this.createCampaignRequest = (data) => Instance_default.post(
      this.createRequestURL({
        option: "campaign"
      }),
      data
    );
    /**
     *
     * @param data
     */
    this.updateCampaignRequest = (data) => Instance_default.put(
      this.createRequestURL({
        option: "campaign"
      }),
      data
    );
    /**
     *
     * @param campaignId
     */
    this.deleteCampaignRequest = (campaignId) => {
      const ids = campaignId.split(",");
      if (ids.length < 2) {
        return Instance_default.delete(
          this.createRequestURL({
            option: "campaign",
            id: campaignId
          })
        );
      } else {
        return Instance_default.post(
          this.createRequestURL({
            option: "campaign",
            task: "deleteAll"
          }),
          {
            id: campaignId
          }
        );
      }
    };
    /**
     *
     * @param page
     * @param limit
     */
    this.getCampaignListRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "campaign",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
    this.getCampaignMasterDataRequest = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "campaign",
          task: "getMasterData"
        })
      );
    };
    this.searchCampaignsRequest = (dataFilter, page = 1, limit = 20) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "campaign",
          task: "filterCampaign",
          limitStart: (page - 1) * limit,
          limit,
          ...dataFilter
        })
      );
    };
  }
};
var CampaignRoute_default = CampaignRoute;

export {
  CampaignRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
